import axios from 'axios';
import { useAuth } from './authentication';
import { z } from 'zod';

export class Report {
    constructor(
        public name: string,
        public domain: string,
        public title: string,
        public admin_only: boolean,
        public id: string,
        public category:string,
        public icon: string,
        public index?: number,
        public description?: string
    ) { }
    static parse({ name, domain, title, admin_only, id, category, icon, index, description }:
        { name: string, domain: string, title: string, admin_only: boolean, id: string, category: string, icon: string, index?: number, description?: string }) {
        return new Report(name, domain, title, admin_only, id, category, icon, index, description);
    }
}

export class User {
    constructor(
        public email: string,
        public domain: string,
        public isAdmin: boolean,
    ) { }

    static parse({ email, domain, is_admin }: { email: string, domain: string, is_admin: boolean }) {
        return new User(email, domain, is_admin);
    }
}

export class TrackedDrive {
    constructor(
        public id: string,
        public user: string,
        public domain: string,
        public trackingRequestTimestamp: string,
        public status: string,
        public fileCount: string,
        public name: string,
        public backgroundImageLink: string
    ) {}

    static parse({id, user, domain, trackingRequestTimestamp, status, fileCount, name, backgroundImageLink }: any) {
        return new TrackedDrive(id,user, domain, trackingRequestTimestamp, status, fileCount, name, backgroundImageLink);
    }
}

export class DomainDrive {
    constructor(
        public id: string,
        public createdTime: string,
        public fileCount: string,
        public name: string,
        public backgroundImageLink: string
    ) {}

    static parse({id, fileCount, name, backgroundImageLink, createdTime}: any) {
        return new DomainDrive(id, createdTime, fileCount, name, backgroundImageLink);
    }
}

const DomainConfigSchema = z.object({
    domain: z.string(),
    language: z.string(),
    secrets: z.object({
        delegatedsa: z.string(),
        domainadmin: z.string(),
        gsiscannersa: z.string(),
    }),
    tables: z.object({
        directory: z.object({
            accounts: z.string(),
            groups: z.string(),
            group_members: z.string(),
        }),
        drive: z.object({
            shared_drives: z.string(),
            shared_drive_permissions: z.string(),
            shared_drive_files: z.string(),
            shared_drive_file_permissions: z.string(),
        }),
        activity_logs: z.string(),
    }),
    modules: z.object({
        shared_drive_security: z.object({
            enabled: z.boolean(),
            report_URL_template: z.string(),
        }),
        my_gsi: z.object({
            enabled: z.boolean(),
        }),
    }),
});

export type DomainConfig = z.infer<typeof DomainConfigSchema>;

const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST!;

export function useClient() {
    const { logout } = useAuth();

    return  {
        getReport: wrap(getReport, logout),
        listReports: wrap(listReports, logout),
        getUser: wrap(getUser, logout),
        listTrackedDrives: wrap(listTrackedDrives, logout),
        getTrackedDrive: wrap(getTrackedDrive, logOut),
        listDomainDrives: wrap(listDomainDrives, logout),
        startTrackingDrive: wrap(startTrackingDrive, logout),
        stopTrackingDrive: wrap(stopTrackingDrive, logout),
        getDomainConfig: wrap(getDomainConfig, logout),
        getAlertConfig: wrap(getAlertConfig, logout),
        saveAlertConfig: wrap(saveAlertConfig, logout),
        deleteAlertConfig: wrap(deleteAlertConfig, logout)
    }
}
const cache = {} as any;

function wrap<T extends Function>(func: T, logoutFunction: Function) {
    const wrapper = async (...args: any[]) => {
        try {
            return await func(...args);
        } catch (e: any) {
            if(e.response?.status && (e.response.status === 401)) {
                console.log("Not logged in.")
                logoutFunction();
                throw e;
            }
            throw e;
        }
    }

    return wrapper as unknown as T;
}

async function getReport(reportName: string, invalidateCache=false){
    const cacheKey = "getReport:" + reportName;
    if (!invalidateCache) {
        if (cache[cacheKey])
            return cache[cacheKey] as Report;
    }

    const res = await axios.get(BACKEND_HOST + "/api/get-report/" + reportName, {withCredentials: true});

    const result = Report.parse(res.data);
    cache[cacheKey] = result;
    return result;
}

async function listReports(invalidateCache=false) {
    const cacheKey = "listReports";
    if (!invalidateCache) {
        if (cache[cacheKey])
            return cache[cacheKey];
    }

    const res = await axios.get(BACKEND_HOST + "/api/list-reports", {withCredentials: true});

    const result = res.data.map((item: any) => Report.parse(item));
    cache[cacheKey] = result;
    result.forEach((element: any) => { //also updates the getReport cache.
        const key = "getReport:" + element["name"];
        cache[key] = element;
    });
    return result;
}

async function getUser() {
    const res = await axios.get(BACKEND_HOST + "/api/get-user", {withCredentials:true});
    const result =  User.parse(res.data);
    return result;
}

async function listTrackedDrivesPaginated(cursor?:string): Promise<[TrackedDrive[], string]> {
    const res = await axios.get(BACKEND_HOST + "/api/list-tracked-drives", {
        params: {cursor : cursor},
        withCredentials: true
    });
    const result = res.data.tracked_drives.map((drive:any) => TrackedDrive.parse(drive));
    return [result, res.data.cursor];
}

async function listTrackedDrives(): Promise<TrackedDrive[]> {
    const drives = []
    let cursor: string | undefined;
    do {
        const [drivesInPage, nextCursor] = await listTrackedDrivesPaginated(cursor);
        drives.push(...drivesInPage)
        cursor = nextCursor;
    } while (cursor);

    return drives;
}

async function getTrackedDrive(driveId: string) {
    // TODO we might want to actually implement this endpoint later.
    return listTrackedDrives()
        .then(drives => drives.find(drive => drive.id === driveId))
}

async function getDomainConfig(): Promise<DomainConfig> {
    return axios.get(BACKEND_HOST + "/api/get-domain-config", {
        withCredentials: true
    }).then(res => DomainConfigSchema.parse(res.data));
}

async function listDomainDrives(): Promise<DomainDrive[]> {
    const res = await axios.get(BACKEND_HOST + "/api/list-drives-in-domain", {
        withCredentials: true
    });
    const result = res.data.map((drive:any) => DomainDrive.parse(drive));
    return result;
}

async function startTrackingDrive(driveId: string): Promise<any> {
    const res = await axios.post(BACKEND_HOST + "/api/start-tracking-drive", {
        driveId
    }, {withCredentials : true});
    return res.data;
}

async function stopTrackingDrive(driveId: string): Promise<any> {
    const res = await axios.post(BACKEND_HOST + "/api/stop-tracking-drive", {
        driveId
    }, {withCredentials: true});
    return res.data;
}

export type AlertConfig = {
    events: {
        sensitive_file_extensions: string[],
        sharing_outside_drive: boolean,
        sharing_outside_domain: boolean,
        sharing_with_specified_domains: boolean,
        specified_domains?: string[]
    },
    receivers: string []
}

async function getAlertConfig(driveId: string): Promise<AlertConfig | null> {
    const res = await axios.get(BACKEND_HOST + "/api/get-alert-config", {
        params: {driveId},
        withCredentials: true
    });
    if (res.status === 204) {
        return null;
    }

    return {
        events: {
            sensitive_file_extensions: res.data["events"]["sensitive_file_extensions"],
            sharing_outside_domain: res.data["events"]["sharing_outside_domain"],
            sharing_outside_drive: res.data["events"]["sharing_outside_drive"],
            sharing_with_specified_domains: res.data["events"]["sharing_with_specified_domains"],
            specified_domains: res.data["events"]["specified_domains"]
        },
        receivers: res.data["receivers"]
    }
}

async function saveAlertConfig(driveId: string, config: AlertConfig) {
    const body = {
        driveId,
        // the domain parameter is added by the backend.
        events: {
            ...config.events,
            "sensitive_file_extensions": config.events.sensitive_file_extensions
        },
        receivers: config.receivers
    }

    const res = await axios.post(BACKEND_HOST + "/api/set-alert-config", body, {
        params: {driveId},
        withCredentials: true
    });

    return res.data;
}

async function deleteAlertConfig(driveId: string) {
    const res = await axios.delete(BACKEND_HOST + "/api/delete-alert-config", {
        params: {driveId},
        withCredentials: true
    });
    return res.data;
}

export async function logIn(idToken: string) {
    const res = await axios.get(BACKEND_HOST + "/api/log-in", {
        withCredentials: true,
        headers: {
            "Authorization": `Bearer ${idToken}`
        }
    });
    const result =  User.parse(res.data);
    return result;
}

export async function logOut() {
    await axios.get(BACKEND_HOST + "/api/log-out", {withCredentials: true});
    return;
}