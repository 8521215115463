import { Outlet, Route } from "react-router-dom";
import { RequireAuthentication } from "../../authentication";
import { RequireAdmin } from "../../authorization";
import MyReports, { ReportsType } from "./common/MyReports";
import ReportDisplay from "./common/ReportDisplay";

function ModuleWrapper() {
    return(<RequireAuthentication>
        <RequireAdmin>
            <Outlet />
        </RequireAdmin>
    </RequireAuthentication>);
}

export default (<Route path="/app/global-reports" element={<ModuleWrapper />}>
    <Route index element={ <MyReports type={ReportsType.ADMIN} /> } />
    <Route path="/app/global-reports/:reportName" element={<ReportDisplay />} />
</Route>)