import { useDomainConfig } from "../../context";
import en from "../../translations/en.json";
import fr from "../../translations/fr.json";

const translations = {
    en, fr
} as {[key: string] : typeof en};

export default function useTranslate() {
    const domainConfig = useDomainConfig();
    const lang =  domainConfig?.language || "en";
    const dictionary = translations[lang] ? translations[lang] :  translations["en"]   
    return (textKey: TranslationKey) => (dictionary[textKey]) ? dictionary[textKey] : en[textKey];
}

export type TranslationKey = keyof typeof en;