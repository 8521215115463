import { Box } from "@mui/material"

export default function EmbeddedLookerReport({ reportPath }: { reportPath: string }) {
    return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%" overflow="hidden">
        <Box maxWidth="1000px" height="100%" width="100%" alignSelf="baseline" paddingTop="24px">
            <iframe
                title='GSI Report'
                width="100%"
                height="100%"
                src={`https://lookerstudio.google.com/embed/reporting/${reportPath}`}
                frameBorder="0"
                style={{ border: 0 }}
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            />
        </Box>
    </Box>)
}