import React, { useContext, useEffect } from "react";
import { TrackedDrive, useClient } from "../../api";
import { useLocation } from "react-router-dom";

let CurrentTrackedDriveContext = React.createContext<TrackedDrive | undefined>(null!);

export function CurrentTrackedDriveProvider({children}: {children: React.ReactNode}) {
    const [currentTrackedDrive, setCurrentTrackedDrive] = React.useState<TrackedDrive>();
    const location = useLocation();
    const { getTrackedDrive } = useClient();
    useEffect(() => {
        setCurrentTrackedDrive(undefined);
        const prefix = "/app/security-and-alerting/";
        if(location.pathname.startsWith(prefix) 
            && location.pathname.length > prefix.length) {
            const driveId = location.pathname.substring(prefix.length).split("/")[0];
            if(driveId !== "add-tracked-drives") // TODO can we handle this better?
                getTrackedDrive(driveId).then(setCurrentTrackedDrive);
        }
    }, [location]);
    return <CurrentTrackedDriveContext.Provider
     value={currentTrackedDrive}
     >{children}</CurrentTrackedDriveContext.Provider>
}

export function useCurrentTrackedDrive() {
    return useContext(CurrentTrackedDriveContext);
}