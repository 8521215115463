import React, { useEffect, useState } from "react";
import { Report, useClient } from "../../../api";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActionArea, CardContent, LinearProgress, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../common/Icon";
import { useAuth } from "../../../authentication";
import SimpleMessagePage from "../../common/SimpleMessagePage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useTranslate, { TranslationKey } from "../../common/useTranslate";

type Category = {name: string, name_key: TranslationKey, color: string, description_key: TranslationKey};

export const GLOBAL_REPORT_CATEGORIES = [
    {
        name: "Adoption",
        name_key: "reportCategory.adoption.name",
        color: "#1565c0",
        description_key: "reportCategory.adoption.description"
    },
    {
        name: "Sharing Activity",
        name_key: "reportCategory.sharing_activity.name",
        color: "#1565c0",
        description_key: "reportCategory.sharing_activity.description"
    },
    {
        name: "Support",
        name_key: "reportCategory.support.name",
        color: "#1565c0",
        description_key: "reportCategory.support.description"
    },
    {
        name: "Carbon Footprint",
        name_key: "reportCategory.carbon_footprint.name",
        color: "#1565c0",
        description_key: "reportCategory.carbon_footprint.description"
    }
] as Category[];

export const USER_REPORT_CATEGORIES = [
    { 
        name: "My Statistics",
        name_key: "reportCategory.my_statistics.name",
        color: "#1565c0",
        description_key: "reportCategory.my_statistics.description"
    },
    {
        name: "Me vs Global Stats",
        name_key: "reportCategory.me_vs_global_stats.name",
        color: "#1565c0",
        description_key: "reportCategory.me_vs_global_stats.description"
    },
    {
        name: "My Evolution",
        name_key: "reportCategory.my_evolution.name",
        color: "#1565c0",
        description_key: "reportCategory.my_evolution.description"
    },    
] as Category[];

function ReportLink({ report }: { report: Report }) {
    const navigate = useNavigate();
    const to = `/app/${report.admin_only ? "global-reports" : "individual-reports"}/${report.name}`;

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Tooltip title={report.description && <div style={{whiteSpace: "pre-line"}}>{report.description}</div>}>
                <Card sx={{ margin: "8px", maxWidth: "100px" }} elevation={2}>
                    <CardActionArea
                        onClick={() => navigate(to)}
                    >
                        <CardContent sx={{ padding: "0px" }}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                alignItems="center"
                                width={90} height={90}
                            >
                                <Box textAlign="center" padding={1}>{report.title}</Box>
                                <Icon name={report.icon} width={36} height={36} style= {{margin: "8px", marginTop: 0}}></Icon>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Tooltip>
        </Box>
        );
}

export const reportOrderComparator = (a: Report, b: Report) =>  {
    if(!a.index)
        return 1;
    if(!b.index)
        return -1;
    return a.index - b.index;
}

function ReportListForCategory({ reports, category }: { reports: Report[], category: Category }) {
    const translate = useTranslate();
    const reportsForThisCategory = reports.filter(report => report.category === category.name).sort(reportOrderComparator);
    return (
        <Accordion sx={{marginBottom: "12px"}} elevation={2}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                key={category.name_key}
                color={category.color}
                disabled={reportsForThisCategory.length === 0}
            >
                <Box display="flex" flexDirection="column">
                    <Typography 
                        variant="h5"
                        sx={{
                            fontFamily: "\"Helvetica\"",
                            fontWeight: "bold",
                            color: category.color,
                            ":firstLetter": "capitalize"
                        }}>{translate(category.name_key)}</Typography>
                    <Typography 
                        variant = "h6"
                        sx={{
                        fontSize: "16px",
                        fontFamily: "\"Helvetica\"",
                        color: category.color,
                        ":firstLetter": "capitalize"
                    }}>{translate(category.description_key)}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" flexWrap="wrap">
                    {
                        reportsForThisCategory
                            .map(report => <ReportLink key={report.name} report={report} />)
                    }
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export enum ReportsType { ADMIN, USER };

export default function MyReports({ type }: { type: ReportsType }) {
    const [reports, setReports] = useState<Report[]>();
    const { listReports } = useClient();
    const { user } = useAuth();
    const translate = useTranslate();

    const categoryOrderComparator = (a: any, b: any) =>  {
        if(reports?.filter(report => report.category === a.name).length === 0)
            return 1;
        if(reports?.filter(report => report.category === b.name).length === 0)
            return -1;
        return 0;
    }

    useEffect(() => {
        document.title = type === ReportsType.ADMIN ? `GSI - ${translate("globalReports.name")}` : `GSI - ${translate("endUser.name")}`;
    }, [type]);

    useEffect(() => {
        const reportFilter = (report: Report) => report.admin_only === (type === ReportsType.ADMIN);
        listReports().then(
            res => res.filter(reportFilter)
        ).then(setReports);
    }, [type]);

    if (!user.isAdmin && type === ReportsType.ADMIN)
        return <SimpleMessagePage message={translate("auth.requires_admin")}/>

    if (!reports)
        return <LinearProgress />;

    return (
        <React.Fragment>
            <Box display={'flex'} flexDirection={'column'} height={"100%"}>
                <Box flexGrow="1" display="flex" alignItems="center" justifyContent="center">
                    <Box display="flex" flexDirection="column" flexGrow="1" ml="100px" mr="100px" mt="32px" height="100%" maxWidth="800px">
                        <Box flexGrow="1">
                            <Typography variant="h5" mb="16px" className="greyBodyText">
                                {type === ReportsType.ADMIN ? `${translate("globalReports.name")}` : `${translate("endUser.name")}`}
                            </Typography>
                            {
                                (type === ReportsType.ADMIN ? GLOBAL_REPORT_CATEGORIES : USER_REPORT_CATEGORIES)
                                .sort(categoryOrderComparator).map(category => (
                                    <ReportListForCategory key={category.name_key} reports={reports} category={category} />
                                ))
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>

        </React.Fragment>);
}