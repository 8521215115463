import { Outlet, Route } from "react-router-dom";
import { RequireAuthentication } from "../../authentication";
import MyReports, { ReportsType } from "./common/MyReports";
import ReportDisplay from "./common/ReportDisplay";
import { RequireMyGSIModule } from "../../authorization";

function ModuleWrapper() {
    return(<RequireAuthentication>
        <RequireMyGSIModule>
            <Outlet />
        </RequireMyGSIModule>
    </RequireAuthentication>);
}

export default (<Route path="/app/individual-reports" element={<ModuleWrapper />}>
    <Route index element={ <MyReports type={ReportsType.USER} /> } />
    <Route path="/app/individual-reports/:reportName" element={<ReportDisplay />} />
</Route>)