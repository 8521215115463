import * as React from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import { Report, TrackedDrive } from '../api';
import { useCurrentReport } from '../context';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useCurrentTrackedDrive } from './sd-security/currentTrackedDrive';
import useTranslate, { TranslationKey } from './common/useTranslate';

function toBreadcrumbName(path: string, currentReport: Report | null, currentTrackedDrive: TrackedDrive | undefined, translate: (key:TranslationKey) => string) {
  const patterns = [
    { pattern: /^\/app$/, name: "GSI" },
    { pattern: /^\/app\/individual-reports$/, name: translate("endUser.name") },
    { pattern: /^\/app\/global-reports$/, name: translate("globalReports.name") },
    { pattern: /^\/app\/security-and-alerting$/, name: translate("SDSecurity.name") },
    { pattern: /^\/app\/security-and-alerting\/add-tracked-drives$/, name: translate("SDSecurity.add_tracked_drives.title") },
    { pattern: /^\/app\/security-and-alerting\/.*\/alerts$/, name: translate("generic.alerts") },
  ] as {pattern: RegExp, name: string} [];

  for ( const element of patterns)
    if (element.pattern.test(path))
      return element.name;

  if (currentReport) {
    return `${currentReport.category}: ${currentReport.title}`
  }

  if(currentTrackedDrive)
    return currentTrackedDrive.name;
}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

export default function Crumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const currentReport = useCurrentReport();
  const currentTrackedDrive = useCurrentTrackedDrive();
  const translate = useTranslate();

  return (
    <Breadcrumbs aria-label="breadcrumb"
      separator={<NavigateNextIcon sx={{color:'white'}}/>}
    >
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="text.primary" key={to}
            sx={{
              color: "white",
              fontWeight: "bold"
            }}>
            {toBreadcrumbName(to, currentReport, currentTrackedDrive, translate)}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}
            sx={{
              color: "white",
              fontWeight: "bold"
            }}>
            {toBreadcrumbName(to, currentReport, currentTrackedDrive, translate)}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
}