import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from "@mui/material/Button";
import useTranslate from "../common/useTranslate";

export default function AlertDialog({text, isOpen, close}: any) {
    const translate = useTranslate();
    return (
      <React.Fragment>
        <Dialog
          open={isOpen}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
          </DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} autoFocus>
              {translate("generic.OK")}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  