import drive from "./icons/drive.png"
import chart from "./icons/chart.png"
import speedometer from "./icons/speedometer.png"
import speedometers from "./icons/speedometers.png"
import lock from "./icons/lock.png";
import meet from "./icons/meet.png";
import headset from "./icons/headset.png";
import gmail from "./icons/gmail.png";
import environment from "./icons/environment.png";
import meter from "./icons/meter.png";
import microsoft_google from "./icons/microsoft_google.png";
import shared_drive from "./icons/shared_drive.png";
import user from "./icons/user.png";

const icons = {
    drive,
    chart,
    speedometer,
    speedometers,
    lock,
    meet,
    headset,
    gmail,
    environment,
    meter,
    microsoft_google,
    shared_drive,
    user
} as any;

export function Icon({name, height, width, style}: {name:string, height?:number, width?:number, style?: any}) {
    return <img
        src={icons[name]}
        alt=""
        width={width ? `${width}px` : undefined}
        height={height ? `${height}px` : undefined}
        style={style}
    />
}