import { Avatar, Box, CircularProgress, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { IconButton } from "@mui/joy";
import { RequireAuthentication, useAuth } from "../../authentication";
import { AlertConfig, TrackedDrive, useClient } from "../../api";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Link, Navigate, Outlet, Route, useNavigate } from "react-router-dom";
import StopTrackingDriveDialog from "./StopTrackingDriveDialog";
import { AddTrackedDrives } from "./AddTrackedDrives";
import TrackedDrivePage, { TrackedDriveReport } from "./TrackedDrivePage";
import { RequireAdmin, RequireSharedDriveSecurityModule } from "../../authorization";
import { CurrentTrackedDriveProvider } from "./currentTrackedDrive";
import useTranslate from "../common/useTranslate";
import AlertsPage from "./AlertsPage";

function TrackedSharedDriveRow({drive, openStopTrackingDialog}: {drive: TrackedDrive, openStopTrackingDialog: (drive: TrackedDrive) => void}) {
    const { getAlertConfig } = useClient();
    const [alertConfig, setAlertConfig] = useState<AlertConfig | null>();
    const [fetchingAlertConfig, setFetchingAlertConfig] = useState(true);
    const translate = useTranslate();
    useEffect(() => {
        setFetchingAlertConfig(true);
        getAlertConfig(drive.id)
            .then(setAlertConfig)
            .finally(() => setFetchingAlertConfig(false));
    }, []); 

    const alertsAreOn = alertConfig 
        && alertConfig.receivers.length > 0
        && (
            alertConfig.events.sharing_outside_domain 
            || alertConfig.events.sharing_outside_drive
            || alertConfig.events.sensitive_file_extensions.length > 0
        );

    const statusForHumans = (status: string) => {
        if(status === "TRACKED")
            return translate("SDSecurity.status.tracked");
        if(status === "LISTING_FILES")
            return translate("SDSecurity.status.listing_files");
        if(status === "LISTING_PERMISSIONS")
            return translate("SDSecurity.status.listing_permissions");
        if(status === "FILE_LISTING_FAILED")
            return translate("SDSecurity.status.file_listing_failed");
        if(status === "PERMISSION_LISTING_FAILED")
            return translate("SDSecurity.status.permission_listing_failed");
        return status;
    }

    return <Grid item xs={12} mt={1}>
        <Paper sx ={{padding: "8px"}}>
            <Grid container >
                <Grid item xs={1}>
                    <Avatar variant="rounded" src={drive.backgroundImageLink} />
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                    {
                        drive.status === "TRACKED"
                            ? <Link
                                to={`/app/security-and-alerting/${drive.id}`}
                            ><Typography variant="body2">{drive.name}</Typography></Link>
                            : <Typography variant="body2">{drive.name}</Typography>
                    }
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                    <Typography variant="body2">{drive.fileCount} {translate("generic.files")}</Typography>
                </Grid>
                <Grid item xs={3} display="flex" alignItems="center">
                    <Typography variant="body2">{drive.id}</Typography>
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                    <Typography variant="body2">{statusForHumans(drive.status)}</Typography>
                </Grid>
                <Grid item xs={1} display="flex" alignItems="center">
                    <Typography variant="body2">{
                        fetchingAlertConfig
                            ? <CircularProgress size="20px"/>
                            : <Link to={`/app/security-and-alerting/${drive.id}/alerts`}>
                                {alertsAreOn
                                    ? translate("generic.ON")
                                    : translate("generic.OFF")}
                            </Link>
                        }</Typography>
                </Grid>
                <Grid item xs={1} display="flex" justifyContent="end">
                    <IconButton
                        onClick={ () => openStopTrackingDialog(drive)}
                        disabled={drive.status !== "TRACKED"}
                    ><DeleteIcon/></IconButton>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
    
}

function TrackedSharedDrivesList(props: any) {
    const translate = useTranslate();

    if (!props.trackedDrives)
        return null;

    return <Box>
            <Box sx={{padding:"8px"}}>
                <Grid container >
                <Grid xs={1}></Grid>
                <Grid xs={2}>{translate("generic.name")}</Grid>
                <Grid xs={2}>{translate("generic.size")}</Grid>
                <Grid xs={3}>{translate("generic.id")}</Grid>
                <Grid xs={2}>{translate("generic.status")}</Grid>
                <Grid xs={1}>{translate("generic.alerts")}</Grid>
                <Grid xs={1}></Grid>
            </Grid>
        </Box>
        {props.trackedDrives.map((d: TrackedDrive) => <TrackedSharedDriveRow key={d.id} drive={d} {...props}/>)}
    </Box>    
}

function SharedDriveSecurity () {
    const translate = useTranslate();
    const navigate = useNavigate();
    const [trackedDrives, setTrackedDrives] = useState<TrackedDrive[]>();
    const [drivesLoading, setDrivesLoading] = useState(false);
    const{ listTrackedDrives } = useClient(); 

    const refreshDrivesList = () => {
        setDrivesLoading(true);
        listTrackedDrives()
            .then(setTrackedDrives)
            .then(() => setDrivesLoading(false));
    }
        
    useEffect(()=> {
         refreshDrivesList();
    }, []);

    const [driveToStopTracking, setDriveToStopTracking] = useState<TrackedDrive>();
    const [stopTrackingDialogOpen, setStopTrackingDialogOpen] = useState(false);
    const openStopTrackingDialog = (drive: TrackedDrive) => {
        setDriveToStopTracking(drive);
        setStopTrackingDialogOpen(true);
    };

    useEffect(() => {
        document.title = `GSI - ${translate("SDSecurity.name")}`
    }, []);

    return <Box padding={4}>
        <Typography variant="h5" mb="16px" className="greyBodyText">
            {translate("SDSecurity.name")}
        </Typography>
        <StopTrackingDriveDialog
            drive={driveToStopTracking}
            isOpen={stopTrackingDialogOpen}
            close={(refresh: boolean) => {
                setStopTrackingDialogOpen(false);
                if(refresh)
                    refreshDrivesList();
            }}
        />
        <Typography variant="body2">{translate("SDSecurity.welcome")}</Typography>
        <Box display="flex" justifyContent="space-between" mb={3} mt={3}>
            <Box display="flex" alignItems="center">
                <Typography variant="h6" mb={0}>{translate("SDSecurity.tracked_drives_for_your_domain")}</Typography>
            </Box>
            <IconButton
                variant="outlined"
                color="primary"
                onClick={() => navigate("/app/security-and-alerting/add-tracked-drives")}
            ><AddIcon/></IconButton>
        </Box>
        {
            !drivesLoading && (
                trackedDrives && trackedDrives.length > 0
                    ? <TrackedSharedDrivesList trackedDrives={trackedDrives} openStopTrackingDialog={openStopTrackingDialog}/>
                    : <Box display="flex" flexDirection="column" justifyContent="center">
                        <Typography variant="body1" textAlign="center">{translate("SDSecurity.no_tracked_drives")}</Typography>
                        <Typography variant="body1" textAlign="center">{translate("SDSecurity.click_plus")}</Typography>
                    </Box>
                )
        }
        
        {drivesLoading && <LinearProgress />}
    </Box>
}

function ModuleWrapper() {
    return(<RequireAuthentication>
        <RequireAdmin>
            <RequireSharedDriveSecurityModule>
                    <Outlet />
            </RequireSharedDriveSecurityModule>
        </RequireAdmin>
    </RequireAuthentication>);
}

export default (<Route path="/app/security-and-alerting" element={<ModuleWrapper />}>
    <Route index element={ <SharedDriveSecurity /> } />
    <Route path="/app/security-and-alerting/add-tracked-drives" element={<RequireAuthentication><AddTrackedDrives /></RequireAuthentication>} />
    <Route path="/app/security-and-alerting/:sharedDriveId" element={<RequireAuthentication><TrackedDrivePage/></RequireAuthentication>}>
        <Route path="/app/security-and-alerting/:sharedDriveId/alerts" element={<AlertsPage />}/>
        <Route index element={<TrackedDriveReport />} />
    </Route>
    <Route path="/app/security-and-alerting/*" element={<Navigate to="/app/security-and-alerting"/>} />
</Route>)