import { Box, Button, Chip, CircularProgress, FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup, Switch, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { AlertConfig, useClient } from "../../api";
import useTranslate from "../common/useTranslate";
import { useOutletContext } from "react-router-dom";

const defaultAlertConfig = {
    events: {
        sensitive_file_extensions: [],
        sharing_outside_domain: false,
        sharing_outside_drive: false,
        sharing_with_specified_domains: false,
        specified_domains: undefined
    },
    receivers: []
} as AlertConfig;

// taken from https://support.google.com/mail/answer/6590#zippy=%2Cmessages-that-have-attachments
const default_sensitive_file_extensions = [
    "ade", "adp", "apk", "appx", "appxbundle", "bat", "cab", "chm", "cmd", "com", "cpl", "diagcab",
    "diagcfg", "diagpkg", "dll", "dmg", "ex", "ex_", "exe", "hta", "img", "ins", "iso", "isp", "jar",
    "jnlp", "js", "jse", "lib", "lnk", "mde", "mjs", "msc", "msi", "msix", "msixbundle", "msp", "mst",
    "nsh", "pif", "ps1", "scr", "sct", "shb", "sys", "vb", "vbe", "vbs", "vhd", "vxd", "wsc", "wsf",
    "wsh", "xll",
]

export default function AlertsPage() {
    const { getAlertConfig, saveAlertConfig } = useClient();
    const [alertConfig, setAlertConfig] = useState<AlertConfig | null>();
    const [changesMade, setChangesMade] = useState(false);
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [receiver, setReceiver] = useState("");
    const [specifiedDomain, setSpecifiedDomain] = useState("");
    const [fileExtension, setFileExtension] = useState("");
    const translate = useTranslate();
    const { driveId } = useOutletContext<{driveId: string}>();

    useEffect(() => {
        getAlertConfig(driveId).then((config) => {
            if (!config) {
                setAlertConfig(defaultAlertConfig);
            } else {
                setAlertConfig(config);
            }
        });
    }, [driveId]);

    if (!alertConfig)
        return <LinearProgress />

    const setReceivers = (receivers: string[]) => {
        const newConfig = {...alertConfig}
        newConfig.receivers = receivers;
        setAlertConfig(newConfig)
        setChangesMade(true);
    }

    const setSpecifiedDomains = (specifiedDomains: string[]) => {
        const newConfig = {...alertConfig}
        newConfig.events.specified_domains = specifiedDomains;
        setAlertConfig(newConfig)
        setChangesMade(true);
    }

    const setFileExtensions = (fileExtensions: string[]) => {
        const newConfig = {...alertConfig}
        newConfig.events.sensitive_file_extensions = fileExtensions;
        setAlertConfig(newConfig)
        setChangesMade(true);
    }

    const changeSharingAlertPreferences = (preferences: {sharing_outside_domain: boolean, sharing_outside_drive: boolean, sharing_with_specified_domains: boolean}) => {
        const config = {
            ...alertConfig,
            events: {
                ...alertConfig.events,
                ...preferences
            }
        }
        setAlertConfig(config);
        setChangesMade(true);
    }

    const saveChanges = () => {
        setRequestSubmitted(true);
        saveAlertConfig(driveId, alertConfig)
            .then(() => setChangesMade(false))
            .finally(() => setRequestSubmitted(false));
    }

    const handleRadioGroupChange = (event: ChangeEvent) => {
        const value = (event.target as HTMLInputElement).value;
        if(value === "internal") {
            changeSharingAlertPreferences({
                "sharing_outside_domain": false,
                "sharing_outside_drive": true,
                "sharing_with_specified_domains": false
            });
            return;
        }
        if(value === "external") {
            changeSharingAlertPreferences({
                "sharing_outside_domain": true,
                "sharing_outside_drive": false,
                "sharing_with_specified_domains": false,
            });
            return;
        }

        if(value === "specified") {
            changeSharingAlertPreferences({
                "sharing_outside_domain": false,
                "sharing_outside_drive": false,
                "sharing_with_specified_domains": true,
            });
            return;
        }

    }

    const receiverIsValid = receiver.length > 0 && /^[\w.%+-]+@[\w.-]+\.[\w]{2,}$/.test(receiver);

    return <Box boxSizing="border-box" padding={2}>
        <Box display="flex">
            <Box flex={1} mr={1}>
                <FormControl>
                    <Typography variant="body1">{translate("SDSecurity.alerts.specify_receivers")}</Typography>
                        {
                            <Box mt={1}>
                                {
                                    alertConfig.receivers.map((receiver: string) => <Box
                                        mb={1}
                                        key={receiver}
                                    >
                                        <Chip
                                            disabled={requestSubmitted}
                                            size="small"
                                            label={receiver}
                                            onDelete={() => setReceivers(alertConfig.receivers.filter(r => r !== receiver))}
                                        />
                                    </Box>)
                                }
                                <Box mt={2} display={"flex"} alignItems={"center"}>
                                    <TextField
                                        disabled={requestSubmitted}
                                        variant="outlined"
                                        label={translate("SDSecurity.alerts.add_receiver")}
                                        size="small"
                                        value={receiver}
                                        onChange={e => setReceiver(e.target.value)}
                                        error={receiver.length > 0 && !receiverIsValid}
                                        onKeyDown={(ev: any) => {
                                            if(ev.key === 'Enter') {
                                                setReceivers([...alertConfig.receivers, receiver]);
                                                setReceiver("");   
                                            }
                                        }}
                                    />
                                    <Button
                                        sx={{marginLeft:"8px"}}
                                        variant="outlined"
                                        disabled={!receiverIsValid}
                                        size="medium"
                                        onClick={() => {
                                            setReceivers([...alertConfig.receivers, receiver]);
                                            setReceiver(""); 
                                        }}
                                    >{translate("generic.add")}</Button>
                                </Box>
                            </Box>
                        }
                </FormControl>
            </Box>
            <Box flex={1} ml={1}>
                <FormControl>
                    <Typography variant="body1">{translate("SDSecurity.alerts.specify_events")}</Typography>
                    <FormControlLabel labelPlacement="end" control={
                        <Switch 
                            onChange={(_, checked) => {
                                if(checked)
                                    changeSharingAlertPreferences({
                                        "sharing_outside_domain": true,
                                        "sharing_outside_drive": false,
                                        "sharing_with_specified_domains": false
                                    });
                                else
                                    changeSharingAlertPreferences({
                                        "sharing_outside_domain": false,
                                        "sharing_outside_drive": false,
                                        "sharing_with_specified_domains": false
                                    });
                            }}
                            checked={alertConfig.events.sharing_outside_domain || alertConfig.events.sharing_outside_drive || alertConfig.events.sharing_with_specified_domains}
                        />
                    } label={translate("SDSecurity.alerts.sharing_outside_drive")} />
                    {
                        (alertConfig.events.sharing_outside_domain || alertConfig.events.sharing_outside_drive || alertConfig.events.sharing_with_specified_domains) &&
                        <Box ml={3}>
                            <RadioGroup
                                name="radio-buttons-group"
                                onChange={handleRadioGroupChange}
                                value = {alertConfig.events.sharing_with_specified_domains ? "specified" : (alertConfig.events.sharing_outside_drive ? "internal" : "external")}
                            >
                                <FormControlLabel value="internal" control={<Radio size="small" />} label={translate("SDSecurity.alerts.all_new_users")} componentsProps={{typography: {variant: "body2"}}}/>
                                <FormControlLabel value="external" control={<Radio size="small" />} label={translate("SDSecurity.alerts.external_new_users")} componentsProps={{typography: {variant: "body2"}}}/>
                                <FormControlLabel value="specified" control={<Radio size="small" />} label={translate("SDSecurity.alerts.specific_domain_users")} componentsProps={{typography: {variant: "body2"}}} />
                                {
                                    alertConfig.events.sharing_with_specified_domains && (
                                        <Box mt={1}>
                                            { 
                                                ( alertConfig.events.specified_domains &&
                                                    alertConfig.events.specified_domains.map((domain: string) => <Box
                                                        mb={1}
                                                        key={domain}
                                                    >
                                                        <Chip
                                                            disabled={requestSubmitted}
                                                            size="small"
                                                            label={domain}
                                                            onDelete={() => setSpecifiedDomains(alertConfig.events.specified_domains!.filter(r => r !== domain))}
                                                        />
                                                    </Box>)
                                                )
                                            }
                                             <Box mt={2} display={"flex"} alignItems={"center"}>
                                                <TextField
                                                    disabled={requestSubmitted}
                                                    variant="outlined"
                                                    label={translate("SDSecurity.alerts.add_domain")}
                                                    size="small"
                                                    value={specifiedDomain}
                                                    onChange={e => setSpecifiedDomain(e.target.value)}
                                                    onKeyDown={(ev: any) => {
                                                        if(ev.key === 'Enter' && specifiedDomain.length > 0) {
                                                            setSpecifiedDomains([...(alertConfig.events.specified_domains || []), specifiedDomain]);
                                                            setSpecifiedDomain("");   
                                                        }
                                                    }}
                                                />
                                                <Button
                                                    sx={{marginLeft:"8px"}}
                                                    variant="outlined"
                                                    disabled={specifiedDomain.length === 0}
                                                    size="medium"
                                                    onClick={() => {
                                                        setSpecifiedDomains([...(alertConfig.events.specified_domains || []), specifiedDomain]);
                                                        setSpecifiedDomain(""); 
                                                    }}
                                                >{translate("generic.add")}</Button>
                                            </Box>
                                    </Box>)  
                                }
                            </RadioGroup>
                        </Box>
                    }
                    
                    <FormControlLabel disabled={requestSubmitted} labelPlacement="end" control={
                        <Switch  onChange={(_, checked) => {
                            setFileExtensions(checked ? default_sensitive_file_extensions : []);
                        }} checked={alertConfig.events.sensitive_file_extensions.length > 0}/>
                    } label={translate("SDSecurity.alerts.sensitive_file_types")} />
                    {
                        alertConfig.events.sensitive_file_extensions.length > 0 && <Box mt={1}>
                            {
                                alertConfig.events.sensitive_file_extensions.toSorted().map((extension: string) => <Box
                                    display={"inline"}
                                    mr={1}
                                    mb={1}
                                    mt={1}
                                    key={extension}
                                >
                                    <Chip
                                        disabled={requestSubmitted}
                                        size="small"
                                        label={extension}
                                        onDelete={() => setFileExtensions(alertConfig.events.sensitive_file_extensions.filter(r => r !== extension))}
                                    />
                                </Box>)
                            }
                            <Box mt={2} display={"flex"} alignItems={"center"}>
                                <TextField
                                    disabled={requestSubmitted}
                                    variant="outlined"
                                    label={translate("SDSecurity.alerts.add_file_type")}
                                    size="small"
                                    value={fileExtension}
                                    onChange={e => setFileExtension(e.target.value)}
                                    onKeyDown={(ev: any) => {
                                        if(ev.key === 'Enter') {
                                            setFileExtensions([...alertConfig.events.sensitive_file_extensions, fileExtension]);
                                            setFileExtension("");   
                                        }
                                    }}
                                />
                                <Button
                                    sx={{marginLeft:"8px"}}
                                    variant="outlined"
                                    disabled={fileExtension.length === 0}
                                    size="medium"
                                    onClick={() => {
                                        setFileExtensions([...alertConfig.events.sensitive_file_extensions, fileExtension]);
                                        setFileExtension(""); 
                                    }}
                                >{translate("generic.add")}</Button>
                            </Box>
                        </Box>
                    }
                    <Typography variant="body2"></Typography>
                </FormControl>
            </Box>
        </Box>
        <Box display="flex" justifyContent="end" margin={1}>
            <Button variant="outlined" disabled={!changesMade} onClick={saveChanges}>{requestSubmitted ? <CircularProgress size="24px"/> : translate("generic.save_changes")}</Button>
        </Box>
    </Box>
}