import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';
import { User, logIn, logOut, useClient } from "./api";
import { Box, LinearProgress, Typography } from "@mui/material";
import useTranslate from "./components/common/useTranslate";

interface AuthContextType {
    loggedIn: boolean,
    user: User,
    login: (idToken: string) => Promise<void>,
    logout: () => Promise<void>,
    continueSession: (user: User) => void
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    let [loggedIn, setLoggedIn] = React.useState(false);
    let [user, setUser] = React.useState<User>(new User("email", "domain", false));

    let login = async (idToken: string) => {
        const response = await logIn(idToken);
        setUser(response);
        setLoggedIn(true);
    };

    let continueSession = (user: User) => {
        setUser(user);
        setLoggedIn(true);
    }

    let logout = async () => {
        await logOut();
        setLoggedIn(false);
    }

    let value = { user, loggedIn, login, logout, continueSession };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export function RequireAuthentication({ children }: { children: JSX.Element }) {
    const [checking, setChecking] = useState(true);
    let auth = useAuth();
    let location = useLocation();
    const { getUser } = useClient();

    useEffect(() => {
        if (auth.loggedIn)
            return;

        const checkAuth = async () => {
            try {
                const user = await getUser();
                auth.continueSession(user);
            } catch {
                // not logged in ...
            }
            setChecking(false);
        }
        checkAuth();
    }, [auth]);

    if (!auth.loggedIn && checking) {
        return <LinearProgress />;
    }

    if (!auth.loggedIn) {
        return <Navigate to="/app/login" state={{ from: location }} replace />;
    }

    return children;
}

export function LoginPage() {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState(false);
    let auth = useAuth();
    let navigate = useNavigate();
    let location = useLocation();
    const translate = useTranslate();

    useEffect(() => {
        document.title = "GSI - Login";
    }, []);

    useEffect(() => {
        if(auth.loggedIn)
            navigate("/app/home");
    });

    return (<React.Fragment>
        <Box display="flex" flexDirection="column" height="100%" maxHeight="500px">
            {loading
                ? <LinearProgress />
                : <Box
                    flexGrow="1"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Box mb="16px">
                        {errorMessage
                            ? <div><Typography variant="h5" textAlign="center">{errorMessage}</Typography></div>
                            : <div>
                                <Typography variant="h5" textAlign="center">{translate("auth.welcome")}</Typography>
                                <Typography variant="h5" textAlign="center">{translate("auth.sign_in")}</Typography></div>}
                    </Box>

                    <Box>
                        <GoogleLogin
                            onSuccess={async (credentialResponse) => {
                                setLoading(true);
                                try {
                                    await auth.login(credentialResponse.credential!);
                                    navigate(location.state?.from?.pathname || "/app/home");
                                } catch {
                                    setLoading(false);
                                    setErrorMessage(translate("auth.unauthorized"));
                                }
                            }}
                            onError={() => {
                                setErrorMessage(translate("auth.sign_in_failed"));
                            }}
                        />
                    </Box>
                </Box>
            }
        </Box>

    </React.Fragment>);
}