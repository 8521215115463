import React, { useContext, useEffect } from "react";
import { DomainConfig, Report, useClient } from "./api";
import { useLocation } from "react-router-dom";
import { useAuth } from "./authentication";

interface CurrentReportContextType {
    currentReport: Report | null
}

let CurrentReportContext = React.createContext<CurrentReportContextType>(null!);

export function CurrentReportProvider({children}: {children: React.ReactNode}) {
    const [currentReport, setCurrentReport] = React.useState<Report>();
    const location = useLocation();
    const { getReport } = useClient();
    useEffect(() => {
        setCurrentReport(undefined);
        for(const reportDisplayingPath of ["/app/global-reports/", "/app/individual-reports/"])
            if(location.pathname.startsWith(reportDisplayingPath) 
                && location.pathname.length > reportDisplayingPath.length) {
                const reportName = location.pathname.substring(reportDisplayingPath.length);
                getReport(reportName).then(setCurrentReport)
            }
    }, [location]);
    return <CurrentReportContext.Provider
     value={{currentReport: currentReport ? currentReport : null}}
     >{children}</CurrentReportContext.Provider>
}

export function useCurrentReport() {
    return useContext(CurrentReportContext)?.currentReport;
}

const DomainConfigContext = React.createContext<DomainConfig | undefined>(undefined);

export function DomainConfigProvider({children}: {children: React.ReactNode}) {
    const [domainConfig, setDomainConfig] = React.useState<DomainConfig>();
    const { getDomainConfig } = useClient();
    const { user } = useAuth();
    useEffect(() => {
        getDomainConfig().then(setDomainConfig);
    }, [user]);
    return <DomainConfigContext.Provider value={domainConfig}>
        {children}
    </DomainConfigContext.Provider>
}

export function useDomainConfig() {
    return useContext(DomainConfigContext);
}