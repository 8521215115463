import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { useCurrentReport } from "../../../context";
import EmbeddedLookerReport from "../../common/EmbeddedLookerReport";

export default function ReportDisplay() {
    const { reportName } = useParams();
    const report = useCurrentReport();

    useEffect(() => {
      document.title = report ? `GSI - ${report.title}` : `GSI`
    }, [report]);
  
    if (!reportName)
      return <Navigate to="/app/home" />;
  
    if(!report)
      return <LinearProgress/>
  
    return (<EmbeddedLookerReport reportPath={report.id} />);
  }